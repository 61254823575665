.Plans {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  gap: 4rem;
  position: relative;
  padding-bottom: 50px;
}
.Plans > .programs-header {
  display: flex;
  justify-content: center;
  gap: 5rem;
  color: white;
  font-style: italic;
}
.programs-header > span {
  font-size: 3.5rem;
  text-transform: capitalize;
}
.plans-program {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  padding: 1.5rem;
  width: 15rem;
  gap: 2rem;
}
.plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}
.plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.plan > :nth-child(2) {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1px;
}
.plan > :nth-child(3) {
  font-size: 30px;
  font-weight: bold;
}
.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.benefits {
  font-size: 17px;
}
button {
  background-color: white;
  padding: 0.5rem;
  color: black;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s all;
  border: 0;
}
button:hover {
  background-color: var(--orange);
  transition: 0.3s all;
  color: white;
}
.plans-program > :nth-child(2) > button:hover {
  background-color: black;
  color: white;
}
.plans-program > :nth-child(2) > button {
  color: orange;
}
@media screen and (max-width: 768px) {
  .programs-header {
    flex-direction: column;
    gap: 1rem !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .programs-header > span {
    font-size: 2rem;
  }
  .plans-program {
    flex-direction: column;
  }
  .plans-program > :nth-child(2) {
    transform: none;
  }
}
