.reasons {
  padding: 5rem 2rem 2rem;
  display: flex;
  grid-template: 2rem;
  background-color: var(--background-color);
}
.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}
.left-r > img {
  object-fit: cover;
}
.left-r > :nth-child(1) {
  width: 12rem;
  height: 28rem;
  grid-row: 1/3;
}
.left-r > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/5;
}
.left-r > :nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
}
.left-r > :nth-child(4) {
  width: 10rem;
  grid-column: 3/4;
}
.right-r {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.right-r > span {
  color: var(--orange);
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.right-r > .heading-r {
  color: white;
  font-weight: 800;
  font-size: 4rem;
  text-transform: capitalize;
}
.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.details-r > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.details-r > div > img {
  width: 2rem;
}
.details-r > div > span {
  font-size: 1.4rem;
  color: gray;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.right-r > :nth-child(4) {
  color: gray;
  padding-top: 20px;
}
.partners {
  display: flex;
  gap: 0.9rem;
}
.partners > img {
  width: 3rem;
}
@media screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
    overflow: hidden;
  }
  .left-r {
    grid-auto-rows: auto;
  }
  .left-r > :nth-child(1) {
    width: 7rem;
    height: 17rem;
  }
  .left-r > :nth-child(2) {
    width: 7rem;
    height: 10rem;
  }
  .left-r > :nth-child(3) {
    width: 7rem;
  }
  .left-r > :nth-child(4) {
    width: 7rem;
    height: 6rem;
  }
}
