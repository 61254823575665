:root {
  --light-gray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkgray: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
  --background-color: #f5deb3;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.stroke-text {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.btn {
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid orange;
}
.btn:hover {
  background-color: var(--orange);
  color: white;
  -webkit-box-shadow: 16px -9px 22px -6px rgba(199, 185, 199, 1);
  -moz-box-shadow: 16px -9px 22px -6px rgba(199, 185, 199, 1);
  box-shadow: 16px -9px 22px -6px rgba(199, 185, 199, 1);
  border: 2px solid orange;
}
