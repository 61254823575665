.Join {
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  gap: 27rem;
  padding: 0 2rem;
}
.Left-j {
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
}
.Left-j > :nth-child(1) {
  position: absolute;
  content: "";
  width: 20rem;
  height: 2px;
  background-color: var(--orange);
  border-radius: 15px;
}
.Left-j > span {
  font-size: 3.1rem;
}
.Right-j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.email-container {
  display: flex;
  gap: 1rem;
  background-color: gray;
  padding: 1rem;
}

.email-container > input {
  display: flex;
  padding: 0.5rem 2rem;
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
}
::placeholder {
  color: white;
}
.email-container > button {
  padding: 2px 10px;
  background-color: var(--orange);
  color: white;
}
@media screen and (max-width: 768px) {
  .Join {
    flex-direction: column;
    gap: 1rem;
  }
  .Left-j > :nth-child(1) {
    width: 10rem;
  }
  .Left-j > span {
    font-size: x-large;
  }
  .Left-j {
    font-size: x-large;
    flex-direction: column;
  }
  .Right-j {
    padding: 2rem;
  }
}
