.programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 2rem 3rem;
  background-color: var(--background-color);
}
.header-programs {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}
.program-categories {
  display: flex;
  gap: 1rem;
}
.program-category {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.867);
  padding: 2rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}
.program-category > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}
.program-category > :nth-child(2) {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}
.program-category > :nth-child(3) {
  color: white;
  line-height: 40px;
  font-size: 1rem;
}
.join-now {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.join-now > span {
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.join-now > img {
  width: 1rem;
}
.program-category:hover {
  background: var(--planCard);
  transition: 0.5s all;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .header-programs {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
  }
  .program-categories {
    flex-direction: column;
  }
  .program-category {
    padding: 0.9rem;
  }
}
